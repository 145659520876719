<template>
  <div class="nota-credito-view mx-2">
    <v-row>
      <v-col cols="12" v-if="nota.nota != undefined">
        <v-card>
          <v-card-title>
            Nota credito: {{ nota.nota.folio | folio }}
            <v-spacer></v-spacer>
            <v-btn
              dark
              color="black"
              @click="$router.push('/detallefactura/' + nota.factura.id)"
              >Volver</v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <p>
                      <strong>N° factura asociada:</strong>
                      {{ nota.factura.folio | folio }}
                      <br />
                      <strong>Fecha de factura:</strong>
                      {{ moment(nota.factura.fecha).format("ll") }}
                      <br />
                      <strong>Fecha de nota credito:</strong>
                      {{ moment(nota.nota.created_at).format("ll") }}
                      <br />
                      <strong>Total factura:</strong>
                      {{ nota.factura.total | currency }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <p>
                      <strong>Cliente:</strong>
                      {{ nota.factura.cliente }}
                      <br />
                      <strong>CC/NIT:</strong>
                      {{ nota.factura.cc_cliente }}
                      <br />
                      <strong>Teléfono:</strong>
                      {{ nota.factura.telefono }}
                      <br />
                      <strong>Dirección:</strong>
                      {{ nota.factura.direccion }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Productos -->
              <v-col cols="12" md="12" sm="12" v-if="productos.length > 0">
                <v-card elevation="0" outlined>
                  <v-card-title>Productos</v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Tipo</th>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Valor</th>
                            <th>Subtotal</th>
                            <th>IVA</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in productos" :key="index">
                            <td>{{ item.categoria }}</td>
                            <td>
                              {{ item.salida }}
                            </td>
                            <td>-{{ item.cantidad }}</td>
                            <td>-{{ valor(item) | currency }}</td>
                            <td>-{{ item.subtotal | currency }}</td>
                            <td>-{{ item.iva | currency }}</td>
                            <td>-{{ item.total | currency }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Servicios -->
              <v-col cols="12" md="12" sm="12" v-if="servicios.length > 0">
                <v-card elevation="0" outlined>
                  <v-card-title>Servicios</v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Servicio</th>
                            <th>Doc Equivalente</th>
                            <th>Cantidad</th>
                            <th>Subtotal</th>
                            <th>IVA</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in servicios" :key="index">
                            <td>
                              {{ item.salida }}
                            </td>
                            <td>
                              {{ item.equivalente | folio }}
                            </td>
                            <td>-{{ item.cantidad }}</td>
                            <td>-{{ item.subtotal | currency }}</td>
                            <td>-{{ item.iva | currency }}</td>
                            <td>-{{ item.total | currency }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Total -->
              <v-col cols="12" md="5" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Total factura
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>Subtotal</th>
                            <td>-{{ nota.nota.subtotal | currency }}</td>
                          </tr>
                          <tr>
                            <th>IVA</th>
                            <td>-{{ nota.nota.iva | currency }}</td>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <td>-{{ nota.nota.total | currency }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "notaCreditoView",
  data: () => ({
    nota: {},
  }),
  methods: {
    loadDetalleNotaCredito() {
      const body = {
        route: "/detalle_nota_credito",
        params: {
          id: this.$route.params.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.nota = response.data.data;
        }
      });
    },
    valor(value) {
      return parseFloat(value.total) / parseInt(value.cantidad);
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadDetalleNotaCredito();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    servicios() {
      const servicios = this.nota.nota_productos.filter((salida) => {
        return salida.categoria === "servicio";
      });
      return servicios;
    },
    venta() {
      return this.nota.nota_productos.filter((salida) => {
        return salida.categoria === "venta";
      });
    },
    consumo() {
      const consumo = this.nota.nota_productos.filter((salida) => {
        return salida.categoria === "consumo";
      });
      return consumo;
    },
    productos() {
      return this.consumo.concat(this.venta);
    },
  },
};
</script>
<style scoped>
p {
  color: black;
}
</style>
